<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="isOrdersLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Orders</span
              >
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="text-left">
                    <th class="pl-7" style="width: 30px">
                      Order id
                    </th>
                    <th style="min-width: 120px">Company</th>
                    <th style="min-width: 110px">Total</th>
                    <th style="min-width: 110px">Date</th>
                    <th style="min-width: 120px">Status</th>
                    <th class="pr-7 text-right" style="min-width: 160px">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order, key) in orders" :key="key">
                    <td
                      class="pl-7 py-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <router-link
                        :to="'/orders/details/' + order.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                      >
                        {{ order.orderId }}
                      </router-link>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ order.companyName }}</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ order.total | currencyFormat }} €</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span class="font-weight-bolder d-block font-size-lg">{{
                        order.createdAt | formatDateTime
                      }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="label label-lg label-light-primary label-inline"
                        >{{ order.status }}</span
                      >
                    </td>
                    <td
                      class="pr-7 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <router-link
                        :to="'/orders/details/' + order.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Design/ZoomPlus.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="remove(order.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center" style="width: 100%">
        <b-spinner
          variant="primary"
          type="grow"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '../../../core/services/api.service'

export default {
  name: 'ListOrders',
  data() {
    return {
      orderQuery: '',
      orders: [],
      isOrdersLoaded: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Orders', route: '/orders/list' }
    ])

    this.getOrders(
      'orders',
      '?status=order&order[createdAt]=desc&itemsPerPage=100&properties[]=id&properties[]=orderId&properties[]=status&properties[]=companyName&properties[]=total&properties[]=createdAt'
    ).then(({ data }) => {
      this.orders = [...data['hydra:member']]
      this.isOrdersLoaded = true
    })
  },
  methods: {
    searchOrderByOrderID() {},
    async getOrders(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    remove(id) {
      const confirmation = confirm('You want delete this order?')
      if (confirmation === false) return

      ApiService.delete(`/orders/${id}`).then(({ status }) => {
        if (status === 204) {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Order success deleted from database.'
          })
        }
      })
    }
  }
}
</script>
